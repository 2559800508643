<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <div class="gps-form">
        <el-form
          ref="ruleFormRef"
          :show-message="false"
          :model="ruleForm"
          :rules="rules"
          status-icon
          label-width="90px"
        >
          <el-tab-pane
            v-for="(it_tab, idx) in props.formArray"
            :label="it_tab.label"
            :name="it_tab.name"
            :key="idx"
          >
            <el-row
              :gutter="10"
              v-for="(it, ir) in it_tab.children"
              :key="'r' + ir"
            >
              <el-col :span="24" :offset="0"
                ><el-form-item
                  :label="it.label"
                  :prop="it.field"
                  v-if="it.type != 'empty'"
                  :label-width="220"
                >
                  <div class="flex-row" v-if="it.type == 'ipt_ipts'">
                    <el-input
                      v-for="(ipit, ip_i) in ruleForm[it.field]"
                      :key="ip_i"
                      clearable
                      :placeholder="ruleForm[it.field][ip_i]"
                      v-model="ruleForm[it.field][ip_i]"
                    />
                  </div>
                  <el-tree-select
                    v-if="it.type == 'tsel'"
                    v-model="ruleForm[it.field]"
                    :data="it.data"
                    filterable
                  />
                  <el-input
                    v-if="it.type == 'ipt'"
                    clearable
                    v-model="ruleForm[it.field]"
                    :placeholder="it.label"
                  />
                  <el-input
                    v-if="it.type == 'smpt_ipt'"
                    clearable
                    v-model="ruleForm['EmailSMTP'][it.field]"
                    :placeholder="it.label"
                  />
                  <el-input
                    v-if="it.type == 'pwd'"
                    clearable
                    type="password"
                    show-password
                    v-model="ruleForm[it.field]"
                    :placeholder="it.label"
                  />
                  <el-date-picker
                    clearable
                    v-if="it.type == 'dtr'"
                    v-model="ruleForm[it.field]"
                    type="datetimerange"
                    range-separator="To"
                    :start-placeholder="it.title_s"
                    :end-placeholder="it.title_e"
                    value-format="YYYY-MM-DD hh:mm:ss"
                  />
                  <el-date-picker
                    clearable
                    v-if="it.type == 'date'"
                    v-model="ruleForm[it.field]"
                    type="date"
                    :placeholder="it.label"
                    value-format="YYYY-MM-DD"
                  />
                  <el-date-picker
                    clearable
                    v-if="it.type == 'datetime'"
                    v-model="ruleForm[it.field]"
                    type="datetime"
                    :placeholder="it.label"
                    value-format="YYYY-MM-DD hh:mm:ss"
                  />
                  <el-radio-group
                    v-model="ruleForm[it.field]"
                    v-if="it.type == 'radio'"
                  >
                    <el-radio
                      v-for="item in it.data"
                      :key="item.value"
                      :label="item.value.toString()"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                  <el-radio-group
                    v-model="ruleForm['EmailSMTP'][it.field]"
                    v-if="it.type == 'smpt_radio'"
                  >
                    <el-radio
                      v-for="item in it.data"
                      :key="item.value"
                      :label="item.value.toString()"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                  <el-radio-group
                    v-model="ruleForm[it.field]"
                    v-if="it.type == 'radio_img'"
                  >
                    <el-radio
                      v-for="item in it.data"
                      :key="item.value"
                      :label="item.value.toString()"
                      ><el-image :src="item.path" fit="fill"></el-image>
                    </el-radio>
                  </el-radio-group>
                  <el-select
                    clearable
                    v-if="it.type == 'sel'"
                    v-model="ruleForm[it.field]"
                    :placeholder="it.label"
                    @change="it.change && it.change($event, it, callbackForm)"
                  >
                    <el-option
                      v-for="item in it.data"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value.toString()"
                    />
                  </el-select>
                  <el-select
                    clearable
                    v-if="it.type == 'msel'"
                    multiple
                    v-model="ruleForm[it.field]"
                    :placeholder="it.label"
                  >
                    <el-option
                      v-for="item in it.data"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value.toString()"
                    />
                  </el-select>
                  <el-switch
                    v-if="it.type == 'switch'"
                    v-model="ruleForm[it.field]"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  />
                  <gps-upload
                    v-if="it.type == 'upload'"
                    :field="it.field"
                    :imgPath="ruleForm[it.field]"
                    @UploadEvent="UploadEvent"
                  ></gps-upload> </el-form-item
              ></el-col>
            </el-row>
          </el-tab-pane>
        </el-form>
      </div>
    </el-tabs>
    <div
      style="text-align: center; margin-top: 40px; padding-bottom: 60px"
      class="ttrr"
    >
      <el-button type="primary" @click="save">{{
        t("commKey.SAVE")
      }}</el-button>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, defineEmits, watch, toRaw } from "vue";
import GpsUpload from "../../../components/GpsUpload.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const emit = defineEmits(["saveEvent", "formEvent"]);
/*
[{
  label:'',
  name:'',
  children:[]
}]
*/
const props = defineProps({
  formArray: {
    type: Array,
    default: () => {},
  },
  row: {
    type: Object,
    default: () => {},
  },
  tabFirstName: {
    type: String,
    default: "",
  },
});

const activeName = ref("");
activeName.value = props.tabFirstName;

//表单控制
const ruleFormRef = ref(null);

let ruleFormObj = {};
let rulesObj = {};

const arr = [];
props.formArray.forEach((it) => {
  if (it.children.length > 0) {
    it.children.forEach((item) => {
      //赋值操作
      switch (item.type) {
        case "ipt_ipts":
          if (item.field) ruleFormObj[item.field] = item.val.split(",");
          break;
        case "smpt_ipt":
        case "smpt_radio":
          if (!ruleFormObj["EmailSMTP"]) ruleFormObj["EmailSMTP"] = {};
          if (item.field) ruleFormObj["EmailSMTP"][item.field] = item.val;
          break;
        default:
          if (item.field) ruleFormObj[item.field] = item.val;
          break;
      }
      //验证数据规则
      if (item.required) {
        if (item.type == "smpt_ipt" || item.type == "smpt_radio") {
          if (!rulesObj["EmailSMTP"]) rulesObj["EmailSMTP"] = {};
          rulesObj["EmailSMTP"][item.field] = [
            {
              required: true,
              message: item.label + t("commKey.BITIAN"),
              trigger: "blur",
            },
          ];
        } else {
          rulesObj[item.field] = [
            {
              required: true,
              message: item.label + t("commKey.BITIAN"),
              trigger: "blur",
            },
          ];
        }
      }
    });
  }
});

watch(
  () => props.row,
  (val) => {
    for (let p in val) {
      ruleForm[p] = typeof val[p] === "number" ? val[p].toString() : val[p];
    }
  },
  { deep: true }
);

// console.log("wxw1", JSON.stringify(ruleFormObj));

const ruleForm = reactive(ruleFormObj);
// const rules = reactive(rulesObj);
const rules = ref(rulesObj);

//函数声明
const handleClick = (tab, event) => {
  // console.log(tab.paneName, rules);
  let rulesObj_valite = {};
  props.formArray.forEach((it) => {
    if (it.name == tab.paneName) {
      // console.log(it.children);
      it.children.forEach((item) => {
        //验证数据规则
        if (item.required) {
          if (item.type == "smpt_ipt" || item.type == "smpt_radio") {
            if (!rulesObj_valite["EmailSMTP"])
              rulesObj_valite["EmailSMTP"] = {};
            rulesObj_valite["EmailSMTP"][item.field] = [
              {
                required: true,
                message: item.label + t("commKey.BITIAN"),
                trigger: "blur",
              },
            ];
          } else {
            rulesObj_valite[item.field] = [
              {
                required: true,
                message: item.label + t("commKey.BITIAN"),
                trigger: "blur",
              },
            ];
          }
        }
      });
    }
  });
  rules.value = rulesObj_valite;
};
const save = async () => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      emit("saveEvent", toRaw(ruleForm));
    } else {
      console.log("error submit!", fields);
    }
  });
};
const UploadEvent = (obj) => {
  ruleForm[obj.field] = obj.img;
};
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.gps-form {
  padding-top: 30px;
}
.ttrr .el-button {
  width: 150px;
  height: 35px;
  border-radius: 10px;
  margin-top: 20px;
}
::v-deep .el-dialog__body .gps-form {
  padding-top: 0px !important;
}
</style>
